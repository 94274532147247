import { handleActions } from 'redux-actions';

import {
  estimateFormSubmissionCostAction,
} from './AssistantActionConfig';

// reducers
const defaultState = {
  data: {},
};

const reducer = handleActions({
  [estimateFormSubmissionCostAction](state, { payload }) {
    const { data } = payload;
    return { ...state, data };
  },
}, defaultState);

export default reducer;
